import { createReducer, on } from '@ngrx/store';
import * as NavActions from '../actions/nav.action';

export interface NavState {
  menuOpen: boolean;
  showProgramCtaButton: boolean;
}

export const initialState: NavState = {
  menuOpen: false,
  showProgramCtaButton: true,
};

export const NavReducer = createReducer(
  initialState,
  on(NavActions.OpenMenu, state => {
    return {
      ...state,
      menuOpen: true,
    };
  }),
  on(NavActions.CloseMenu, state => {
    return {
      ...state,
      menuOpen: false,
    };
  }),
  on(NavActions.ToggleMenu, state => {
    return {
      ...state,
      menuOpen: !state.menuOpen,
    };
  }),
  on(NavActions.ShowProgramCtaButton, state => {
    return {
      ...state,
      showProgramCtaButton: true,
    };
  }),
  on(NavActions.HideProgramCtaButton, state => {
    return {
      ...state,
      showProgramCtaButton: false,
    };
  })
);

export const getMenuOpen = (state: NavState) => state.menuOpen;
export const getShowProgramCTAButton = (state: NavState) => state.showProgramCtaButton;
